// App.js
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import { AppProvider } from './context/AppContext';
import Header from './components/Header';
import Navigation from './components/Navigation';
import Home from './pages/Home';
import Nominees from './pages/Nominees';
import VoteActiveWinner from './pages/VoteActiveWinner';
import About from './pages/About';
import Test from './pages/Test';

import ParallaxBackground from './components/ParallaxBackground';
import Footer from './components/Footer';


// Helper to get random background
const getRandomBackground = () => {
  // We'll need to set up an endpoint or config to get folder names
  const backgroundFolders = ['lagoon', 'sunny-hills', 'cyberpunk-street', 'pink-sunset', 'light-forest', 'green-forest', 'desert', 'cave-battle'];
  return backgroundFolders[Math.floor(Math.random() * backgroundFolders.length)];
};

const selectedBg = getRandomBackground();

const layers = [
  { image: `/backgrounds/${selectedBg}/back.webp`, speed: 0.2 },
  { image: `/backgrounds/${selectedBg}/middle.webp`, speed: 0.5 },
  { image: `/backgrounds/${selectedBg}/front.webp`, speed: 0.8 },
  { image: `/zigbg.webp`, speed: 0.0 }
];


function App() {
  return (
    <AppProvider>
      <Router>
        <div className="min-h-screen flex flex-col relative">
          <ParallaxBackground layers={layers} />
          <Header />
          {/* Navigation */}
          <Navigation />

          {/* Page Content */}
          <div className="flex-grow max-w-4xl mx-auto xl:p-4 p-2">
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/nominees" element={<Nominees />} />
              <Route path="/vote" element={<VoteActiveWinner />} />
              <Route path="/about" element={<About />} />
              <Route path="/test" element={<Test />} />
            </Routes>
          </div>
          <Footer />
        </div>
      </Router>
    </AppProvider>
  );
}

export default App;
