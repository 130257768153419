import { Link } from 'react-router-dom';
import { useAppContext } from '../context/AppContext';
import TextArea from '../components/TextArea';

export default function Home() {
  const { sharedMessage, setSharedMessage } = useAppContext();

  return (
    <TextArea>
      <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>Welcome to Yet Another Game Awards Show</h1>
      <p className="text-3xl text-gray-100 px-6">
          YAGA is Yet Another Game Awards Show.
          <br></br><br></br>
          YAGA is for everyone who loves games. We are here to recognize and celebrate outstanding games and developers.
          <br></br><br></br>
          2024 has been an incredible year for gaming. We have been playing great new games all year long.
          <br></br><br></br>
          Now, we are excited to hear about what You have been playing. 
          <br></br><br></br>
          YAGA is now accepting votes for the best games and developers of 2024.
          <br></br><br></br>
          Click ↴ to vote!
        </p>
        <br></br>
        <Link to="/vote" className="mx-5 px-5 py-2 text-2xl text-gray-900 hover:bg-yellow-500 active:bg-yellow-600 transition-colors bg-[#fff444] rounded-1g" style={{"border-radius": "5px !important"}}>Vote Now!</Link>
    </TextArea>
  );
}