const TextArea = ({ children }) => {
    return (
    <div className="flex justify-center" style={{ fontFamily: 'ABeeZee' }}>
      <div className="max-w-[100vw] xl:max-w-[90vw] min-w-[50vw] bg-[#000069] xl:py-8 py-5 rounded-lg">
        <div className="xl:max-w-[50vw] mx-auto xl:px-5 px-3">
            {children}
        </div>
      </div>
    </div>
    );
  };

  export default TextArea;