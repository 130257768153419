import { useState, useEffect } from 'react';
import { useAppContext } from '../context/AppContext';

export default function NomineeForm () {
  const { voteData, updateVotes, handleSubmission } = useAppContext();
  const [submitStatus, setSubmitStatus] = useState('');
  const [formData, setFormData] = useState({
    bestSoloDev: '',
    bestSmallTeam: '',
    bestNewStudio: '',
    bestEarlyAccess: '',
    bestSelfPublished: '',
    bestGameplay: '',
    bestNarrative: '',
    bestArtDirection: '',
    bestOriginalScore: '',
    bestSong: '',
    bestAudioDesign: '',
    bestWorldBuilding: '',
    bestCameraWork: '',
    bestPerformance: '',
    mostImpactful: '',
    mostInnovative: '',
    bestOngoing: '',
    bestCommunitySupport: '',
    bestItchioGame: '',
    bestAction: '',
    bestAdventure: '',
    bestRPG: '',
    bestHorror: '',
    bestPlatformer: '',
    bestFPS: '',
    bestSimStrategy: '',
    bestFighting: '',
    bestVisualNovel: '',
    bestMultiplayer: '',
    bestRemakeRerelease: '',
    bestDLC: '',
    bestAAA: '',
    bestIndie: '',
    bestDebutIndie: '',
    mostAnticipated: '',
    gameOfTheYear: ''
  });

  useEffect(() => {
    if (voteData) {
      setFormData(voteData);
    }
  }, [voteData]);

  const handleInputChange = (field) => (value) => {
    try {
      const newData = { 
        ...formData, 
        [field]: value
      };
      setFormData(newData);
      // Only pass the data object, not the entire event
      updateVotes(newData);
    } catch (error) {
      console.error('Error updating form:', error);
    }
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const success = handleSubmission();
    
    if (success) {
      setSubmitStatus('Saved successfully!');
      setTimeout(() => setSubmitStatus(''), 5000);
    }
  };

  // Example of nominees data structure
  const nominees = {
    bestSoloDev: [
    'LocalThunk (Balatro)',
    'LWMedia (Nightmare Kart)',
    'lamedeveloper (Webfishing)',
    'Bureau 81 (The Operator)',
    'Moonless Formless (Withering Rooms)',
    'Molegato (Frogun Encore)',
    'Tomas Sala (Bulwark Evolution: Falconeer Chronicles)',
    'Billy Basso (Animal Well)',
    'Walter Machado (Bloodsaint, Quickerflak 2, & Bullethell)',
    'Daniel Linssen (Leap Year)',
    'Parachor (Reliquia Park)',
    'FLEB (20 Small Mazes)',
    'YORUROJI (CLONY)',
    'Blink Hydrate (Push the Cat With WASD)',
    'René Rother (Children of the Sun)',
    'Suspicious Developments (Tatical Breach Wizards)'],
    bestSmallTeam: [
    'à la mode games (Sorry We\'re Closed)',
    'Talha and Jack Co. (Judero)',
    'Wrong Organ (Mouthwashing)',
    'Svavelstickan (Star Fetchers: Escape From Pork Belly)',
    'Ánimo Games Studio (Star Stuff)',
    'Miriam and Lage (Stig)',
    'Mango Protocol (CLeM)',
    'Calligram Studio (Phoenix Springs)',
    'Boomo (Slider)',
    'SFB Games (Crow Country)',
    'Odd-Meter (Indika)',
    'Aggro Crab (Another Crab\'s Treasure)',
    'Mossmouth (UFO 50)',
    'Ironwood Studios (Pacific Drive)',
    'Night Signal Entertainment (Home Safety Hotline)',
    'Strange Scaffold (I Am Your Beast)',],
    bestNewStudio: [
    'Slow Bros (Harold Halibut) ',
    'Sunset Visitor (1000xResist)',
    'Balloon Studios (Botany Manor)',
    'Pocketpair (Palworld)',
    'Day 4 Night Studios (TBA)',
    'Calligram Studio (Phoenix Springs)',],
    bestEarlyAccess: [
    'Real Web Legends: Carter\'s Quest',
    'Fields of Mistria ',
    'Enshrouded',
    'Hades 2',
    'Selaco',],
    bestSelfPublished: [
    'Fields of Mistria',
    'Webfishing',
    'Nightmare Kart',
    'Judero',
    'Harold Halibut',
    '20 Small Mazes',
    'Reliquia Park',
    'UFO 50',
    'Another Crab\'s Treasure',
    'Home Safety Hotline',
    'Tactical Breach Wizards',],
    bestGameplay: [
    'Indiana Jones & The Great Circle',
    'Cryptmaster',
    'Sorry We\'re Closed',
    'Perennial Order',
    'Leap Year',
    'Push the Cat With WASD',
    'Slider',
    'Tormenture',
    'Mullet MadJack',
    'Tactical Breach Wizards',
    'I Am Your Beast',
    'The Operator',
    'Helldivers 2',
    'Withering Rooms',
    'Sonic X Shadow Generations',
    'Star Fetchers: Escape From Pork Belly',],
    bestNarrative: [
    'Loco Motive',
    'Mouthwashing',
    '1000xResist',
    'Metaphor: ReFantazio',
    'Judero',
    'Still Wakes The Deep',],
    bestArtDirection: [
    'Botany Manor',
    'Harold Halibut',
    'Judero',
    'Sorry We\'re Closed',
    'Metaphor: ReFantazio',
    'Mullet MadJack',
    'Hades 2',
    'Phoenix Springs',
    'Paper Trail',
    'Star Fetchers: Escape From Pork Belly',
    'Arctic Eggs',
    'Nightmare Kart',],
    bestOriginalScore: [
    'Judero by: Talha Kaya, Jack King-Spooner & Beth Sbresni',
    'Harold Halibut by: Onat Hekimoğlu & Holger Schnausen',
    'Star Fetchers: Escape From Pork Belly by: Saffron',
    'Mullet MadJack by: Fernando Pepe and Mateus Polati',
    'Hades 2 by: Darren Korb',
    'Phoenix Springs by: Alexander Smith',
    'Star Stuff by: Ánimo Games Studio',
    'Gambit Shifter by: Loris Spanu',
    'Withering Rooms by: Moonless Formless',],
    bestSong: [
    'Polluted Platoon Pathfinder (Another Crab\'s Treasure)',
    'Exhaust (Star Fetchers: Escape from Pork Belly)',
    'Leave a Note (Botany Manor)',
    'Pantry (Cryptmaster)',
    'Tam Lynn (Judero)',
    'Kongregate (Star Fetchers: Escape from Pork Belly)',
    'Church of Burdens NG (Withering Rooms) ',
    'Without You (Sonic X Shadow Generations)',
    'Jack the Mullet Mad (Mullet MadJack)',],
    bestAudioDesign: [
    'Star Fetchers: Escape From Pork Belly',
    'Gambit Shifter',
    'Star Stuff',
    'Harold Halibut',
    'Mouthwashing',
    'Pacific Drive',],
    bestWorldBuilding: [
    'Harold Halibut',
    'Cryptmaster ',
    '1000xResist ',
    'Judero',
    'Metaphor: ReFantazio',
    'Slider',
    'CLeM',
    'Reliquia Park',
    'Still Wakes The Deep',
    'Mouthwashing',],
    bestCameraWork: [
    'Harold Halibut',
    'Mouthwashing',
    'Silent Hill 2',
    'Real Web Legends: Carter\'s Quest',
    'Sorry We\'re Closed',
    '1000xResist ',],
    bestPerformance: [
    'Jack King-Spooner - Judero & Additional (Judero)',
    'Pat Garrett - Jeanne Mareaux (Harold Halibut)',
    'Angel Haven Rey - Paige (Paper Trail)',
    'Alex Anderson Crow - Iris (Phoenix Springs)',
    'Stewart Clarke - Leon Strohl da Haliaetus (Metaphor: ReFantazio)',],
    mostImpactful: [
    '1000xResist',
    'Paper Trail',
    'Mouthwashing',
    'Judero',
    'Indika',],
    mostInnovative: [
    'Bulwark Evolution: Falconeer Chronicles',
    'Cryptmaster',
    'Threshold',
    'Harold Halibut',
    'Leap Year',
    'Slider',
    'Push the Cat With WASD',
    'Helldivers 2',
    'Tactical Breach Wizards',
    'Animal Well',
    'Reliquia Park',],
    bestOngoing: [
    'Stardew Valley',
    'Helldivers 2',
    'Exanima',
    'DayZ',
    'No Man\'s Sky',
    'The Binding of Isaac',
    'Control',
    'Honkai Star Rail',
    'Final Fantasy XIV',],
    bestCommunitySupport: [
    'Dwarf Fortress',
    'Final Fantasy XIV',
    'Cult of the Lamb',
    'Stardew Valley',
    'No Man\'s Sky',
    'Baldur\'s Gate III',],
    bestItchioGame: [
    'Rogue-Like Deck Builder',
    'These Heavenly Bodies',
    'This is Not Your House',
    'Nightmare Kart',
    'Reliquia Park',
    'Voices of the Void',],
    bestAction: [
    'Another Crab\'s Treasure',
    'Arco',
    'Warhammer 40,000: Space Marine 2',
    'I Am Your Beast',
    'Star Wars Outlaws',
    'Mullet MadJack',],
    bestAdventure: [
    'Thank Goodness You\'re Here!',
    'Indiana Jones and The Great Circle',
    'The Legend of Zelda: Echoes of Wisdom',
    'Harold Halibut',
    'Dungeons of Hinterberg',
    'Judero',
    'Pacific Drive',],
    bestRPG: [
    'Felvidek',
    'Ys X: Nordics',
    'Metaphor: ReFantazio',
    'Visions of Mana',
    'Like a Dragon: Infinite Wealth',
    'Unicorn Overlord',],
    bestHorror: [
    'Mouthwashing',
    'Crow Country',
    'The Outlast Trials',
    'Withering Rooms',
    'Sorry We\'re Closed',
    'Home Safety Hotline',
    'Still Wakes The Deep',],
    bestPlatformer: [
    'Ultros',
    'UFO 50',
    'Frogun Encore',
    'Leap Year',
    'The Big Catch: Tacklebox',],
    bestFPS: [
    'Bodycam',
    'Mullet MadJack',
    'S.T.A.L.K.E.R. 2',
    'I Am Your Beast',
    'Straftat',],
    bestSimStrategy: [
    'Manor Lords',
    'Tactical Breach Wizards',
    'The Crush House',
    'Core Keeper',],
    bestFighting: [
    'Star Fetchers: Escape from Pork Belly',
    'Dragon Ball: Sparking Zero',
    'Traintracks Combat',
    'TEKKEN 8',],
    bestMultiplayer: [
    'Marvel Rivals',
    'Liar\'s Bar',
    'Dale & Dawson Stationary Supplies ',
    'Lockdown Protocol ',
    'Buckshot Roulette ',
    'Content Warning',
    'The Outlast Trials',
    'Super Mario Party Jamboree',
    'Webfishing',
    'Nightmare Kart',],
    bestRemakeRerelease: [
    'Tomba! Special Edition',
    'Persona 3 Reload',
    'Ghost of Tsushima',
    'Final Fantasy VII Rebirth',
    'Tomb Raider 1-3 Remastered',
    'Silent Hill 2',],
    bestDLC: [
    'Star Fetchers: Escape from Pork Belly',
    'Factorio Space Age',
    'Alan Wake 2: The Lake House',
    'Elden Ring: Shadow of the Erdtree',],
    bestAAA: [
    'Indiana Jones and the Great Circle',
    'Metaphor: ReFantazio',
    'Sonic X Shadow Generations',
    'Star Wars Outlaws',
    'S.T.A.L.K.E.R. 2',
    'TEKKEN 8',],
    bestIndie: [
    'Mouthwashing',
    'Balatro',
    'Dungeons of Hinterberg',
    'Crow Country',
    'Webfishing',
    'Core Keeper',
    'Judero',
    '1000xResist',
    'Reliquia Park',
    'Leap Year',
    'Nightmare Kart',
    'Pacific Drive',
    'I Am Your Beast',
    'Indika',
    'Mullet MadJack',],
    bestDebutIndie: [
    'Sorry We\'re Closed',
    'Balatro',
    'Withering Rooms',
    'Slider',
    'Stig',
    'Webfishing',
    'Pacific Drive',
    '1000xResist ',
    'Judero',],
    mostAnticipated: [
    'Pokemon Legends: ZA',
    'Ghost of Yotei',
    'Silly Polly Beast',
    'The Elder Scrolls VI',
    'Puzzle Depot',
    'You Can\'t See Ghosts',
    'Retchid',
    'Mistified',
    'Deltarune Chapter 3',],
    gameOfTheYear: [
    'Balatro',
    'S.T.A.L.K.E.R. 2',
    'Sonic X Shadow Generations',
    'Leap Year',
    'Judero',
    '1000xResist',
    'Metaphor: ReFantazio',
    'Indiana Jones and the Great Circle']
  };

  return (
    <form onSubmit={handleSubmit} className="max-w-5xl mx-auto space-y-6 p-6">
      <div className="space-y-8">
        <FormField
          label="Best Solo Developer"          
          values={nominees.bestSoloDev}
          selectedValue={formData.bestSoloDev}
          onChange={handleInputChange('bestSoloDev')}
        />
        
        <FormField
          label="Best Small Developer Team (less than 15)"          
          values={nominees.bestSmallTeam}
          selectedValue={formData.bestSmallTeam}
          onChange={handleInputChange('bestSmallTeam')}
        />
        
        <FormField
          label="Best New Studio"          
          values={nominees.bestNewStudio}
          selectedValue={formData.bestNewStudio}
          onChange={handleInputChange('bestNewStudio')}
        />
        
        <FormField
          label="Best Early Access"          
          values={nominees.bestEarlyAccess}
          selectedValue={formData.bestEarlyAccess}
          onChange={handleInputChange('bestEarlyAccess')}
        />
        
        <FormField
          label="Best Self Published"          
          values={nominees.bestSelfPublished}
          selectedValue={formData.bestSelfPublished}
          onChange={handleInputChange('bestSelfPublished')}
        />
        
        <FormField
          label="Best Gameplay"          
          values={nominees.bestGameplay}
          selectedValue={formData.bestGameplay}
          onChange={handleInputChange('bestGameplay')}
        />
        
        <FormField
          label="Best Narrative"          
          values={nominees.bestNarrative}
          selectedValue={formData.bestNarrative}
          onChange={handleInputChange('bestNarrative')}
        />
        
        <FormField
          label="Best Art Direction"          
          values={nominees.bestArtDirection}
          selectedValue={formData.bestArtDirection}
          onChange={handleInputChange('bestArtDirection')}
        />
        
        <FormField
          label="Best Original Score"          
          values={nominees.bestOriginalScore}
          selectedValue={formData.bestOriginalScore}
          onChange={handleInputChange('bestOriginalScore')}
        />
        
        <FormField
          label="Best Song"          
          values={nominees.bestSong}
          selectedValue={formData.bestSong}
          onChange={handleInputChange('bestSong')}
        />
        
        <FormField
          label="Best Audio Design"          
          values={nominees.bestAudioDesign}
          selectedValue={formData.bestAudioDesign}
          onChange={handleInputChange('bestAudioDesign')}
        />
        
        <FormField
          label="Best World Building"          
          values={nominees.bestWorldBuilding}
          selectedValue={formData.bestWorldBuilding}
          onChange={handleInputChange('bestWorldBuilding')}
        />
        
        <FormField
          label="Best Camera Work"          
          values={nominees.bestCameraWork}
          selectedValue={formData.bestCameraWork}
          onChange={handleInputChange('bestCameraWork')}
        />
        
        <FormField
          label="Best Performance"          
          values={nominees.bestPerformance}
          selectedValue={formData.bestPerformance}
          onChange={handleInputChange('bestPerformance')}
        />
        
        <FormField
          label="Most Impactful"          
          values={nominees.mostImpactful}
          selectedValue={formData.mostImpactful}
          onChange={handleInputChange('mostImpactful')}
        />
        
        <FormField
          label="Most Innovative"          
          values={nominees.mostInnovative}
          selectedValue={formData.mostInnovative}
          onChange={handleInputChange('mostInnovative')}
        />
        
        <FormField
          label="Best Ongoing"          
          values={nominees.bestOngoing}
          selectedValue={formData.bestOngoing}
          onChange={handleInputChange('bestOngoing')}
        />
        
        <FormField
          label="Best Community Support"          
          values={nominees.bestCommunitySupport}
          selectedValue={formData.bestCommunitySupport}
          onChange={handleInputChange('bestCommunitySupport')}
        />
        
        <FormField
          label="Best Itch.io Game"          
          values={nominees.bestItchioGame}
          selectedValue={formData.bestItchioGame}
          onChange={handleInputChange('bestItchioGame')}
        />
        
        <FormField
          label="Best Action"          
          values={nominees.bestAction}
          selectedValue={formData.bestAction}
          onChange={handleInputChange('bestAction')}
        />
        
        <FormField
          label="Best Adventure"          
          values={nominees.bestAdventure}
          selectedValue={formData.bestAdventure}
          onChange={handleInputChange('bestAdventure')}
        />
        
        <FormField
          label="Best RPG"          
          values={nominees.bestRPG}
          selectedValue={formData.bestRPG}
          onChange={handleInputChange('bestRPG')}
        />
        
        <FormField
          label="Best Horror"          
          values={nominees.bestHorror}
          selectedValue={formData.bestHorror}
          onChange={handleInputChange('bestHorror')}
        />
        
        <FormField
          label="Best Platformer"          
          values={nominees.bestPlatformer}
          selectedValue={formData.bestPlatformer}
          onChange={handleInputChange('bestPlatformer')}
        />
        
        <FormField
          label="Best FPS"          
          values={nominees.bestFPS}
          selectedValue={formData.bestFPS}
          onChange={handleInputChange('bestFPS')}
        />
        
        <FormField
          label="Best Sim/Strategy"          
          values={nominees.bestSimStrategy}
          selectedValue={formData.bestSimStrategy}
          onChange={handleInputChange('bestSimStrategy')}
        />
        
        <FormField
          label="Best Fighting"          
          values={nominees.bestFighting}
          selectedValue={formData.bestFighting}
          onChange={handleInputChange('bestFighting')}
        />
        
        <FormField
          label="Best Multiplayer"          
          values={nominees.bestMultiplayer}
          selectedValue={formData.bestMultiplayer}
          onChange={handleInputChange('bestMultiplayer')}
        />
        
        <FormField
          label="Best Remake/Rerelease"          
          values={nominees.bestRemakeRerelease}
          selectedValue={formData.bestRemakeRerelease}
          onChange={handleInputChange('bestRemakeRerelease')}
        />
        
        <FormField
          label="Best DLC"          
          values={nominees.bestDLC}
          selectedValue={formData.bestDLC}
          onChange={handleInputChange('bestDLC')}
        />
        
        <FormField
          label="Best AAA"          
          values={nominees.bestAAA}
          selectedValue={formData.bestAAA}
          onChange={handleInputChange('bestAAA')}
        />
        
        <FormField
          label="Best Indie"          
          values={nominees.bestIndie}
          selectedValue={formData.bestIndie}
          onChange={handleInputChange('bestIndie')}
        />
        
        <FormField
          label="Best Debut Indie"          
          values={nominees.bestDebutIndie}
          selectedValue={formData.bestDebutIndie}
          onChange={handleInputChange('bestDebutIndie')}
        />
        
        <FormField
          label="Most Anticipated"          
          values={nominees.mostAnticipated}
          selectedValue={formData.mostAnticipated}
          onChange={handleInputChange('mostAnticipated')}
        />
        
        <FormField
          label="Game of the Year"          
          values={nominees.gameOfTheYear}
          selectedValue={formData.gameOfTheYear}
          onChange={handleInputChange('gameOfTheYear')}
        />
      </div>

      <div className="flex justify-end space-x-4 pt-6">
        <button
          type="submit"
          className="mx-5 px-5 py-2 text-2xl text-gray-900 hover:bg-yellow-500 active:bg-yellow-600 transition-colors bg-[#fff444] rounded-1g"
          style={{"border-radius": "5px !important"}}
        >
          Submit Votes
        </button>
        {submitStatus && (
          <div className="text-green-600 py-2">{submitStatus}</div>
        )}
      </div>
    </form>
  );
};

// Reusable form field component
const FormField = ({ label, values, selectedValue, onChange }) => (
  <div className="space-y-2">
    <label className="block text-2xl font-medium text-[#fff444]">{label}</label>
    <div className="space-y-2">
      {values?.map((value, index) => (
        <div key={`${label}_${value}_${index}`} className="flex items-center space-x-4">
          <input
            type="radio"
            name={label}
            id={`${label}_${value}`}
            value={value}
            checked={selectedValue === value}
            onChange={() => onChange(value)}
            className="h-4 w-4 text-blue-600 focus:ring-2 focus:ring-blue-500 border-gray-300"
          />
          <label htmlFor={`${label}_${value}`} className="text-lg font-medium text-white">
            {value}
          </label>
        </div>
      ))}
    </div>
  </div>
);