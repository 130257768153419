import { createContext, useState, useContext, useEffect } from 'react';
import { v4 as uuidv4 } from 'uuid';

const AppContext = createContext();

export function AppProvider({ children }) {
  const [sharedMessage, setSharedMessage] = useState('Hello from context!');
  const [counter, setCounter] = useState(0);

  const [voteData, setVoteData] = useState(() => {
    // Check localStorage on initial load
    const saved = localStorage.getItem('winnerData');
    return saved ? JSON.parse(saved) : {};
  });

  const [userID] = useState(() => {
    let id = localStorage.getItem('userID');
    if (!id) {
      id = uuidv4();
      localStorage.setItem('userID', id);
    }
    return id;
  });

  const updateVotes = (newVotes) => {
    setVoteData(newVotes);
  };

  // Save to localStorage whenever voteData changes
  useEffect(() => {
    localStorage.setItem('winnerData', JSON.stringify(voteData));
  }, [voteData]);

  const incrementCounter = () => {
    setCounter(prev => prev + 1);
  };

  const handleSubmission = async () => {
    const submission = {
      userID,
      timestamp: new Date().toISOString(),
      votes: voteData
    };
  
    // Local save
    const submissions = JSON.parse(localStorage.getItem('submissions') || '[]');
    submissions.push(submission);
    localStorage.setItem('submissions', JSON.stringify(submissions));
  
    // Server save
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/submissions`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(submission)
      });
      return response.ok;
    } catch (err) {
      console.error('Server submit failed:', err);
      return false;
    }
  };

  return (
    <AppContext.Provider value={{ 
      sharedMessage, 
      setSharedMessage,
      counter,
      incrementCounter,
      voteData,
      updateVotes,
      handleSubmission,
      userID
    }}>
      {children}
    </AppContext.Provider>
  );
}

export function useAppContext() {
  return useContext(AppContext);
}
