import WinnerForm from '../components/WinnerForm';
import TextArea from '../components/TextArea';

export default function Vote() {
    return (
        <TextArea>
            <h1 className="text-4xl font-bold mb-6 text-[#fff444]" style={{ fontFamily: 'Belanosima', fontWeight: '400' }}>Vote for the YAGA Winners</h1>
            <p className="text text-gray-100 mb-2">
            Feel free to skip categories, we appreciate any and all votes!
            </p>
            <WinnerForm />
        </TextArea>
    );
  }
